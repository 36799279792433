import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '../../components';
import { RefundAndReturnPolicyContent } from '../../containers/RefundAndReturnPolicyPage/RefundAndReturnPolicyPage';

import css from './Disclaimer.module.css';

const { string, func } = PropTypes;

const Disclaimer = props => {
  const {
    rootClassName,
    className,
    intl,
    refundPolicyAssetsData,
    refundPolicyFetchInProgress,
    refundPolicyFetchError,
    author,
  } = props;
  const [refundPolicyModalOpen, setRefundPolicyModalOpen] = useState(false);

  const classes = classNames(rootClassName || css.root, className);

  const brandNameFull = process.env.REACT_APP_BRAND_NAME_FULL;

  const handleRefundPolicyClick = e => {
    e.preventDefault();
    setRefundPolicyModalOpen(true);
  };

  const handleCloseRefundPolicyModal = () => {
    setRefundPolicyModalOpen(false);
  };

  const isBusiness = author?.attributes?.profile?.publicData?.isBusiness;

  const supportEmail = 'support@brandback.de';
  const emailSubject = `${brandNameFull} ${intl.formatMessage({
    id: 'Disclaimer.buyerProtectionSubject',
  })}`;
  const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(emailSubject)}`;

  return (
    <div className={classes}>
      <div className={css.contentWrapper}>
        <div className={css.container}>
          <div className={css.title}>
            <FormattedMessage id="Disclaimer.buyerProtection" />
          </div>
          <div className={css.textContent}>
            <FormattedMessage
              id="Disclaimer.buyerProtectionText"
              values={{
                refundPolicyLink: (
                  <button className={css.textLink} onClick={handleRefundPolicyClick}>
                    <FormattedMessage id="Disclaimer.refundPolicy" />
                  </button>
                ),
                supportLink: (
                  <a href={mailtoLink} className={css.textLink}>
                    <FormattedMessage id="Disclaimer.reachOutToSupport" />
                  </a>
                ),
              }}
            />
          </div>
          <div className={css.note}>
            <FormattedMessage id="Disclaimer.reviewImagesNote" />
          </div>
        </div>
      </div>

      <Modal
        id="refund-policy-modal"
        isOpen={refundPolicyModalOpen}
        onClose={handleCloseRefundPolicyModal}
        onManageDisableScrolling={props.onManageDisableScrolling}
        usePortal
      >
        <div className={css.refundPolicyWrapper}>
          <div className={css.sellerBanner}>
            <span className={css.sellerBannerText}>
              <FormattedMessage
                id={isBusiness ? 'Disclaimer.professionalSeller' : 'Disclaimer.privateSeller'}
              />
            </span>
          </div>
          <RefundAndReturnPolicyContent
            inProgress={refundPolicyFetchInProgress}
            error={refundPolicyFetchError}
            data={refundPolicyAssetsData?.refundAndReturnPolicy?.data}
          />
        </div>
      </Modal>
    </div>
  );
};

Disclaimer.defaultProps = {
  rootClassName: null,
  className: null,
  refundPolicyAssetsData: null,
  refundPolicyFetchInProgress: false,
  refundPolicyFetchError: null,
  author: null,
};

Disclaimer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onManageDisableScrolling: func.isRequired,
  refundPolicyAssetsData: PropTypes.object,
  refundPolicyFetchInProgress: PropTypes.bool,
  refundPolicyFetchError: PropTypes.object,
  author: PropTypes.object,
};

export default injectIntl(Disclaimer);
